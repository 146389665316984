import styled from '@emotion/styled';
import Select from '@mui/material/Select';
import { AccordionTitle } from 'components/Editor/FormLayout';
import Accordion from 'components/ui/Accordion';
import AccordionDetails from 'components/ui/Accordion/AccordionDetails';
import AccordionSummary from 'components/ui/Accordion/AccordionSummary';
import { breakpoints, mediaMax } from 'styles';

export const LangSelectContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const LangSelect = styled(Select)`
  font-size: 14px;

  .MuiOutlinedInput-notchedOutline {
    border: none;
  }
  &:hover .MuiOutlinedInput-notchedOutline {
    border: none;
  }
  &.Mui-focused .MuiOutlinedInput-notchedOutline {
    outline: none;
    border: none;
  }
  .MuiOutlinedInput-input {
    padding: 0px 0px 0px 10px;
    min-width: 44px;
  }
  .MuiSvgIcon-root {
    width: 18px;
    color: #1e1d20;
  }
`;

export const RenderValue = styled.div`
  display: flex;
  gap: 5px;
`;

export const LangAccordion = styled(Accordion)`
  &.MuiPaper-root {
    padding: 0px 0px 5px 0 !important;

    margin: 0px 20px 5px;
    border-bottom: 1px solid #d9dce1;
    box-shadow: none;
    border-radius: 0;
  }
`;

export const LangSummary = styled(AccordionSummary)`
  .MuiButtonBase-root.MuiAccordionSummary-root {
    padding: 0;
  }
  .MuiAccordionSummary-gutters {
    padding: 0;
  }
  .MuiButtonBase-root {
    padding: 0;
  }
  .MuiAccordionSummary-expandIconWrapper {
    width: 20px;
    margin-right: -5px;
    > svg {
      height: 20px;
      width: 20px;
    }
  }
  padding: 15px 0px 12px 0px;

  ${mediaMax(breakpoints.builder.md)} {
    padding: 15px 0px 12px 0px;
  }
`;
export const LangVariant = styled(AccordionDetails)`
  padding: 0px 15px 15px 0 !important;
  &:hover {
    cursor: pointer;
  }
`;

export const TitleContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 5px;
`;

export const LangTitle = styled(AccordionTitle)`
  font-weight: 400;
  font-size: 16px;
  cursor: pointer;
`;
