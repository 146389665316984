import { FC, memo } from 'react';
import { useTranslation } from 'react-i18next';
import { useAppSelector } from 'hooks';
import { selectUser } from 'store/auth/selectors';
import { LINKS, BUILDER_TEMPLATES_WP_LINKS, SOCIAL_MEDIA } from 'constants/constants';
import LogoSSR from 'components/Headers/components/LogoSSR';
import { useTranslationEditorContext } from 'providers/TranslationEditor';
import { useCheckMobileScreen } from 'hooks/useCheckMobileScreen';
import { breakpoints } from 'styles';
import TranslationSpan from 'components/ui/TranslationSpan';
import Link from 'next/link';

import {
  LogoContainer,
  LinkText,
  FooterWrapper,
  Nav,
  LinksWrapper,
  FooterInner,
  DefFooterContainer,
  DefFooterBottom,
  DefFooterSpan,
  LiteFooterContainer,
  LiteFooterBox,
  LiteFooterCopy,
  MenuTitle,
  MenuColumnLeft,
  MenuColumnRight,
  FooterColumn,
} from './styled';
import FooterCTA from './FooterCTA';
import LanguageSelect from 'components/LanguageSelect';
import { useRouter } from 'next/router';

type FooterLink = {
  name: string;
  textKey: string;
  link: string;
  supportedLanguages?: string[];
};

const CompanyLink = ({ name, textKey, link, supportedLanguages }: FooterLink) => {
  const { locale } = useRouter();

  const { getDataAttributes } = useTranslationEditorContext();

  const linkLocale =
    // For links that have supportedLanguages field, only use current locale if the current language is supported
    // If the field is missing, use the current locale (default behaviour)
    (!supportedLanguages && locale) || (locale && supportedLanguages?.includes(locale) ? locale : false);

  return (
    <li key={link}>
      <Link href={link} locale={linkLocale} rel="noreferrer">
        <LinkText insideAnchor {...getDataAttributes(textKey)}>
          {name}
        </LinkText>
      </Link>
    </li>
  );
};

type FooterProps = {
  noBGImage?: boolean;
  footerType?: string;
  logoLinkTo?: string;
  showLanguagePicker?: boolean;
  showFooterLogin?: boolean;
};

const Footer: FC<FooterProps> = ({
  noBGImage,
  footerType,
  logoLinkTo,
  showLanguagePicker = true,
  showFooterLogin = false,
}) => {
  const { t } = useTranslation();
  const { getDataAttributes } = useTranslationEditorContext();
  const user = useAppSelector(selectUser);
  const { isMobile } = useCheckMobileScreen({ mobileWidth: breakpoints.tabletL - 1 });

  const keys = {
    policy: 'qr.auth.privacy.policy',
    termsOfUse: 'qr.auth.terms.and.conditions',
    contactUs: 'qr.auth.contact.us',
    prices: 'qr.auth.privacy.prices',
    cancelSubscription: 'qr.auth.cancel.subscription',
    faq: 'qr.auth.faq',
    company: 'qr.auth.company',
    allRights: 'qr.auth.allRights',
    aboutUs: 'qr.auth.aboutus',
    blog: 'qr.auth.blog',
    industries: 'qr.auth.industries',
    menuGeneratorTitle: 'qr.menu.generator.title',
    menuCompanyTitle: 'qr.menu.company.title',
    menuHelpTitle: 'qr.menu.help.title',
    menuSocialMediaTitle: 'qr.menu.social.title',
    generatorUrl: 'qr.generator.url.name',
    generatorVCard: 'qr.generator.vcard.name',
    generatorBusiness: 'qr.generator.business.name',
    generatorApp: 'qr.generator.app.name',
    generatorVideo: 'qr.generator.video.name',
    generatorMenu: 'qr.generator.menu.name',
    generatorPdf: 'qr.generator.pdf.name',
    generatorFacebook: 'qr.generator.facebook.name',
    generatorText: 'qr.generator.text.name',
    generatorWifi: 'qr.generator.wifi.name',
    generatorAiQr: 'qr.generator.aiqr.name',
    generatorImages: 'qr.generator.images.name',
    generatorSocial: 'qr.generator.social.name',
    generatorMore: 'qr.generator.more',
    reportAbuse: 'qr.auth.reportAbuse',
    login: 'qr.landing.footer.login',
  };

  const text = {
    policy: t(keys.policy, 'Privacy Policy'),
    termsOfUse: t(keys.termsOfUse, 'Terms of Use'),
    contactUs: t(keys.contactUs, 'Contact us'),
    prices: t(keys.prices, 'Prices'),
    cancelSubscription: t(keys.cancelSubscription, 'Cancel subscription'),
    faq: t(keys.faq, 'FAQ'),
    allRights: t(keys.allRights, '2022 © MyQRCode All rights reserved'),
    aboutUs: t(keys.aboutUs, 'About us'),
    blog: t(keys.blog, 'Blog'),
    industries: t(keys.industries, 'Industries'),
    menuGeneratorTitle: t(keys.menuGeneratorTitle, 'QR Code Generators'),
    menuCompanyTitle: t(keys.menuCompanyTitle, 'Company'),
    menuHelpTitle: t(keys.menuHelpTitle, 'Help'),
    menuSocialMediaTitle: t(keys.menuSocialMediaTitle, 'Connect with us'),
    generatorUrl: t(keys.generatorUrl, 'Link QR Code Generator'),
    generatorVCard: t(keys.generatorVCard, 'VCard QR Code Generator'),
    generatorMenu: t(keys.generatorMenu, 'QR Code Generator for Restaurants'),
    generatorFacebook: t(keys.generatorFacebook, 'Facebook QR Code Generator'),
    generatorPdf: t(keys.generatorPdf, 'PDF QR Code Generator'),
    generatorApp: t(keys.generatorApp, 'QR Code Generator for Mobile App'),
    generatorText: t(keys.generatorText, 'QR Code Text Generator'),
    generatorImages: t(keys.generatorImages, 'QR Code Image Generator'),
    generatorVideo: t(keys.generatorVideo, 'Video QR Code Generator'),
    generatorBusiness: t(keys.generatorBusiness, 'Business QR Code Generator'),
    generatorSocial: t(keys.generatorSocial, 'Social Media QR Code Generator'),
    generatorWifi: t(keys.generatorWifi, 'WiFi QR Code Generator'),
    generatorAiQr: t(keys.generatorAiQr, 'AI QR Code Generator'),
    generatorMore: t(keys.generatorMore, 'More'),
    reportAbuse: t(keys.reportAbuse, 'Report abuse'),
    login: t('qr.landing.footer.login', 'Login'),
  };

  const NAVIGATION = {
    generators: [
      {
        name: text.generatorUrl,
        textKey: keys.generatorUrl,
        link: BUILDER_TEMPLATES_WP_LINKS.URL,
      },
      {
        name: text.generatorVCard,
        textKey: keys.generatorVCard,
        link: BUILDER_TEMPLATES_WP_LINKS.VCARD,
      },
      {
        name: text.generatorMenu,
        textKey: keys.generatorMenu,
        link: BUILDER_TEMPLATES_WP_LINKS.MENU,
      },
      {
        name: text.generatorFacebook,
        textKey: keys.generatorFacebook,
        link: BUILDER_TEMPLATES_WP_LINKS.FACEBOOK,
      },
      {
        name: text.generatorPdf,
        textKey: keys.generatorPdf,
        link: BUILDER_TEMPLATES_WP_LINKS.PDF,
      },
      {
        name: text.generatorApp,
        textKey: keys.generatorApp,
        link: BUILDER_TEMPLATES_WP_LINKS.APP,
      },
      {
        name: text.generatorText,
        textKey: keys.generatorText,
        link: BUILDER_TEMPLATES_WP_LINKS.PLAIN_TEXT,
      },
      {
        name: text.generatorImages,
        textKey: keys.generatorImages,
        link: BUILDER_TEMPLATES_WP_LINKS.IMAGES,
      },
      {
        name: text.generatorVideo,
        textKey: keys.generatorVideo,
        link: BUILDER_TEMPLATES_WP_LINKS.VIDEO,
      },
      {
        name: text.generatorBusiness,
        textKey: keys.generatorBusiness,
        link: BUILDER_TEMPLATES_WP_LINKS.BUSINESS_PAGE,
      },
      {
        name: text.generatorSocial,
        textKey: keys.generatorSocial,
        link: BUILDER_TEMPLATES_WP_LINKS.SOCIAL_MEDIA,
      },
      {
        name: text.generatorWifi,
        textKey: keys.generatorWifi,
        link: BUILDER_TEMPLATES_WP_LINKS.WIFI,
      },
      {
        name: text.generatorAiQr,
        textKey: keys.generatorAiQr,
        link: BUILDER_TEMPLATES_WP_LINKS.AIQR,
      },
    ] as FooterLink[],
    company: [
      {
        name: text.prices,
        textKey: keys.prices,
        link: user ? LINKS.SUBSCRIPTION_PLANS : LINKS.PRICES,
      },
      {
        name: text.aboutUs,
        textKey: keys.aboutUs,
        link: LINKS.ABOUT,
      },
      {
        name: text.blog,
        textKey: keys.blog,
        link: LINKS.BLOG,
        supportedLanguages: ['en', 'es'],
      },
      {
        name: text.industries,
        textKey: keys.industries,
        link: LINKS.INDUSTRIES,
        supportedLanguages: ['en', 'es'],
      },
      {
        name: text.termsOfUse,
        textKey: keys.termsOfUse,
        link: LINKS.TERMS,
      },
      {
        name: text.policy,
        textKey: keys.policy,
        link: LINKS.POLICY,
      },
    ] as FooterLink[],
    help: [
      {
        name: text.contactUs,
        textKey: keys.contactUs,
        link: LINKS.CONTACT_US,
      },
      {
        name: text.cancelSubscription,
        textKey: keys.cancelSubscription,
        link: LINKS.CANCEL_SUBSCRIPTION,
      },
      {
        name: text.faq,
        textKey: keys.faq,
        link: LINKS.FAQ,
      },
      ...(showFooterLogin
        ? [
            {
              name: text.login,
              textKey: keys.login,
              link: LINKS.LOGIN,
            },
          ]
        : []),
    ] as FooterLink[],
    socialMedia: [
      {
        name: SOCIAL_MEDIA.facebook.title,
        link: SOCIAL_MEDIA.facebook.url,
        icon: SOCIAL_MEDIA.facebook.icon,
      },
    ],
    inmobi: [
      {
        name: text.reportAbuse,
        textKey: keys.reportAbuse,
        link: process.env.NEXT_PUBLIC_MAIN_DOMAIN + LINKS.CONTACT_US,
      },
      {
        name: text.contactUs,
        textKey: keys.contactUs,
        link: process.env.NEXT_PUBLIC_MAIN_DOMAIN + LINKS.CONTACT_US,
      },
      {
        name: text.termsOfUse,
        textKey: keys.termsOfUse,
        link: process.env.NEXT_PUBLIC_MAIN_DOMAIN + LINKS.TERMS,
      },
      {
        name: text.policy,
        textKey: keys.policy,
        link: process.env.NEXT_PUBLIC_MAIN_DOMAIN + LINKS.POLICY,
      },
    ] as FooterLink[],
  };

  const LITE_FOOTER_LINKS = [
    {
      name: text.aboutUs,
      textKey: keys.aboutUs,
      link: LINKS.ABOUT,
    },
    {
      name: text.termsOfUse,
      textKey: keys.termsOfUse,
      link: LINKS.TERMS,
    },
    {
      name: text.policy,
      textKey: keys.policy,
      link: LINKS.POLICY,
    },
  ];

  const menuGenerators = NAVIGATION.generators.map(({ name, textKey, link }) => (
    <li key={link}>
      <Link href={link} rel="noreferrer">
        <LinkText insideAnchor {...getDataAttributes(textKey)} className={textKey === keys.generatorMore ? 'more' : ''}>
          {name}
        </LinkText>
      </Link>
    </li>
  ));

  const menuCompany = NAVIGATION.company.map(CompanyLink);

  const menuInmobi = NAVIGATION.inmobi.map(({ name, textKey, link }) => (
    <li key={link}>
      <Link href={link} rel="noreferrer">
        <LinkText insideAnchor {...getDataAttributes(textKey)}>
          {name}
        </LinkText>
      </Link>
    </li>
  ));

  const menuHelp = NAVIGATION.help.map(({ name, textKey, link }) => (
    <li key={link}>
      <Link href={link} rel="noreferrer">
        <LinkText insideAnchor {...getDataAttributes(textKey)}>
          {name}
        </LinkText>
      </Link>
    </li>
  ));

  const DefaultFooter = (
    <FooterInner>
      <DefFooterContainer>
        <FooterColumn>
          <LogoContainer>
            <LogoSSR linkTo={logoLinkTo} />
          </LogoContainer>
          {!isMobile && <FooterCTA />}
        </FooterColumn>
        <Nav>
          <MenuColumnLeft>
            <MenuTitle {...getDataAttributes(keys.menuGeneratorTitle)}>{text.menuGeneratorTitle}</MenuTitle>
            <LinksWrapper data-testid="footer-generator-list">{menuGenerators}</LinksWrapper>
          </MenuColumnLeft>
          <MenuColumnRight>
            <MenuTitle {...getDataAttributes(keys.menuCompanyTitle)}>{text.menuCompanyTitle}</MenuTitle>
            <LinksWrapper data-testid="footer-company-list">{menuCompany}</LinksWrapper>
            <MenuTitle {...getDataAttributes(keys.menuHelpTitle)}>{text.menuHelpTitle}</MenuTitle>
            <LinksWrapper data-testid="footer-help-list">{menuHelp}</LinksWrapper>
          </MenuColumnRight>
        </Nav>
        {isMobile && <FooterCTA />}
      </DefFooterContainer>
      <DefFooterBottom>
        <DefFooterSpan>
          <TranslationSpan phrase="qr.landing.footer.left" />
        </DefFooterSpan>
        {showLanguagePicker ? <LanguageSelect /> : null}
        <DefFooterSpan>
          <TranslationSpan phrase="qr.landing.footer.right" />
        </DefFooterSpan>
      </DefFooterBottom>
    </FooterInner>
  );

  const liteFooterMenuItems = LITE_FOOTER_LINKS.map(({ name, textKey, link }) => (
    <li key={link}>
      <Link href={link} rel="noreferrer">
        <LinkText insideAnchor {...getDataAttributes(textKey)}>
          {name}
        </LinkText>
      </Link>
    </li>
  ));

  const LiteFooter = (
    <LiteFooterContainer>
      <LiteFooterBox data-testid="footer-company-list">{liteFooterMenuItems}</LiteFooterBox>
      <LiteFooterCopy>
        <LinkText {...getDataAttributes(keys.allRights)}>{t(keys.allRights)}</LinkText>
      </LiteFooterCopy>
    </LiteFooterContainer>
  );

  const InmobiFooter = (
    <LiteFooterContainer>
      <LiteFooterBox data-testid="footer-company-list">{menuInmobi}</LiteFooterBox>
      <LiteFooterCopy>
        <LinkText {...getDataAttributes(keys.allRights)}>{t(keys.allRights)}</LinkText>
      </LiteFooterCopy>
    </LiteFooterContainer>
  );

  return (
    <FooterWrapper
      liteFooter={footerType === 'liteFooter' || footerType === 'inmobiFooter'}
      defaultFooter={footerType === 'default'}
      noBGImage={noBGImage}
    >
      {footerType === 'liteFooter' && LiteFooter}
      {footerType === 'inmobiFooter' && InmobiFooter}
      {footerType === 'default' && DefaultFooter}
    </FooterWrapper>
  );
};

export default memo(Footer);
