import styled from '@emotion/styled';
import { breakpoints, mediaMin, COLORS } from 'styles';
import { css } from '@emotion/react';

interface HeaderProps {
  facelift?: boolean;
  miniGenerator?: boolean;
  qrDownload?: boolean;
}

export const Header = styled.header<HeaderProps>`
  width: 100%;
  background: transparent;
  display: flex;
  justify-content: space-between;
  align-items: start;
  padding-top: 14px;
  position: relative;
  z-index: 2;
  padding-top: ${({ facelift }) => (facelift ? '16px' : '14px')};
  @media (max-width: ${breakpoints.mobileL}px) {
    a[href='/cabinet/qr-codes'] {
      margin-left: ${({ qrDownload }) => (qrDownload ? '10px' : '0')};
    }
  }
  ${mediaMin(breakpoints.tabletL)} {
    padding-top: 25px;
  }

  //miniGenerator
  ${({ miniGenerator }) =>
    miniGenerator &&
    css`
      background: white;
      height: 63px;
      position: relative;

      ${mediaMin(breakpoints.tabletL)} {
        background-color: ${COLORS.WHITE};
        position: static;
        height: auto;
      }

      &::after {
        content: '';
        display: block;
        height: 1px;
        background: ${COLORS.BORDER};
        width: calc(100% + 40px);
        position: absolute;
        bottom: 0;
        left: -20px;

        ${mediaMin(breakpoints.tabletXS)} {
          width: calc(100% + 64px);
          left: -32px;
        }
        ${mediaMin(breakpoints.tabletL)} {
          border: none;
          height: 63px;
          background: ${COLORS.WHITE};
          width: 100%;
          top: 0%;
          left: 0;
          z-index: -1;
        }
      }

      //logo
      a > svg {
        width: 144px;
        height: 27px;

        ${mediaMin(breakpoints.tabletL)} {
          width: 150px;
          height: 28px;
        }
      }

      div[data-testid='burger-menu'] {
        top: 50%;
        transform: translateY(-50%);
        margin-top: -2px;
      }
    `}

  //facelift
  ${({ facelift }) =>
    facelift &&
    css`
      a > svg {
        width: 144px;
        height: 27px;

        ${mediaMin(breakpoints.tabletL)} {
          width: 150px;
          height: 28px;
        }
      }
    `}
`;
export const ActionsContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 10px;
`;
